<template>
	<div>
		<CRow>
			<CCol class="text-right">
				<CButton
					:disabled="isExporting"
					class="btn-export mr-3"
					color="tertiary"
					data-test-id="btn-customer"
					@click="exportCustomer"
				>
					{{ isExporting ? "Exporting" : "Export" }}
				</CButton>
				<router-link
					:to="{ name: 'CustomerCreate' }"
					class="btn btn-secondary"
				>
					<CIcon name="cil-plus" class="mr-2" />
					Create account
				</router-link>
			</CCol>
		</CRow>
		<CRow class="mt-4">
			<CCol class="d-flex align-items-start">
				<form
					class="search-form flex-fill"
					@submit.prevent="handleSearch"
				>
					<CInput
						v-model.trim="queryParams.q"
						placeholder="Search by customer ID, name, email or phone no."
					>
						<template #prepend-content>
							<CIcon name="cil-magnifying-glass" data-test-id="search-button" />
						</template>
					</CInput>
				</form>
				<BaseFilterButton
					:is-open="isShowFilter"
					:has-filter="hasFilter"
					class="ml-3"
					data-id="filter-button"
					@onClick="toggleFilter"
				/>
			</CCol>
		</CRow>
		<CRow>
			<CCol>
				<transition name="fade">
					<div
						v-if="isShowFilter"
						class="bg-gray-100 rounded-sm pt-3 px-3 mb-3"
						data-id="show-filter-box"
					>
						<CRow>
							<CCol md="3">
								<BaseDropDown
									v-model="group"
									:options="CUSTOMER_GROUP_OPTIONS"
									label="name"
									label-drop-down="Group name"
									@input="handleSearch"
								/>
							</CCol>
							<CCol md="3">
								<BaseDropDown
									v-model="status"
									:options="CUSTOMER_STATUS_OPTIONS"
									label="name"
									label-drop-down="Customer status"
									@input="handleSearch"
								/>
							</CCol>
							<CCol md="3">
								<BaseInputDate
									:value="createdDate"
									label="Created date"
									placeholder="All period"
									mode="range"
									@input="handleFilterDateChange($event, 'created_from', 'created_to')"
								>
									<template #append-content>
										<CIcon class="cil-calendar" name="cil-calendar" />
									</template>
								</BaseInputDate>
							</CCol>
						</CRow>
					</div>
				</transition>
			</CCol>
		</CRow>
		<CRow>
			<CCol class="mb-4">
				<BaseSearchFormFooter
					:count="list.meta.total"
					append-text="lists found"
					@onReset="handleResetFilter"
				/>
			</CCol>
		</CRow>
		<BaseTable
			:is-loading="list.isLoading"
			:fields="CUSTOMER_FIELDS"
			:items="dataTable"
			:pagination="{
				pages: list.meta.lastPage,
				activePage: list.meta.currentPage
			}"
			striped
			link-to="CustomerEdit"
			class="table-custom"
			@onPaginationClick="handlePageChange"
		>
			<template #no-items-view>
				<div class="empty-table-element">
					<p class="subtitle">
						{{ $t("global.searchNotFound", { field: "customers" }) }}
					</p>
				</div>
			</template>
			<template #status="{ item }">
				<CBadge
					:color="item.color"
					class="badge-status"
				>
					{{ item.name }}
				</CBadge>
			</template>
		</BaseTable>
		<BaseModalConfirm
			ref="modal-export-customer"
			:is-submitting="isExporting"
			:close-after-confirm="false"
			title="Export Customer Management"
			description="The report will be sent to your email after combinded all the files. It might be taking for 5 - 30 mins, please be patient."
			primary-button-text="Send"
			primary-button-loading-text="Sending"
			@onConfirm="handleExportCustomer"
		/>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import BaseModalConfirm from '@/components/BaseModalConfirm.vue';
import { CUSTOMER_STATUS_COLOR, CUSTOMER_STATUS_OPTIONS } from '../enums/customers';
import {
	capitalize,
	cleanObject,
	cloneDeep,
	hasSearchFilter,
	randomSearchString,
	convertDateRangeToUTC,
} from '../assets/js/helpers';
import { postCustomerExport } from '../services/api/customers.api';

export default {
	name: 'CustomerList',
	components: {
		BaseModalConfirm,
	},
	data() {
		return {
			CUSTOMER_STATUS_OPTIONS,
			isShowFilter: false,
			isExporting: false,
			queryParams: {
				q: this.$route.query.q || null,
				customer_status: this.$route.query.customer_status || null,
				customer_group_id: this.$route.query.customer_group_id || null,
				created_from: this.$route.query.created_from || null,
				created_to: this.$route.query.created_to || null,
				page: Number(this.$route.query.page) || null,
				r: randomSearchString(),
			},
		};
	},
	computed: {
		...mapState('customers', {
			list: 'list',
		}),
		...mapGetters({
			customerGroupOptions: 'customerGroups/customerGroupOptions',
		}),
		CUSTOMER_GROUP_OPTIONS() {
			return [
				{ name: 'All groups', value: null },
				...this.customerGroupOptions,
			];
		},
		CUSTOMER_FIELDS() {
			return [
				{ key: 'id', label: 'ID' },
				{ key: 'name', label: 'Name' },
				{ key: 'email', label: 'Email' },
				{ key: 'provider', label: 'Provider' },
				{ key: 'phone', label: 'Phone no.' },
				{ key: 'group', label: 'Group' },
				{ key: 'status', label: 'Status' },
			];
		},
		dataTable() {
			return this.list.data.map((customer) => ({
				id: customer.id,
				name: customer.fullname,
				email: customer.email,
				phone: customer.phone,
				group: customer.customerGroup?.name,
				status: {
					name: capitalize(customer.customerStatus),
					color: CUSTOMER_STATUS_COLOR[customer.customerStatus],
				},
				provider: customer.provider,
			}));
		},
		hasFilter() {
			return hasSearchFilter(this.queryParams);
		},
		status: {
			get() {
				return CUSTOMER_STATUS_OPTIONS.find((option) => option.value === this.queryParams.customer_status);
			},
			set(status) {
				this.queryParams.customer_status = status.value;
				this.updateUrlParams();
			},
		},
		group: {
			get() {
				return this.CUSTOMER_GROUP_OPTIONS.find((option) => option.value === this.queryParams.customer_group_id);
			},
			set(status) {
				this.queryParams.customer_group_id = status.value;
				this.updateUrlParams();
			},
		},
		createdDate() {
			if (!this.queryParams.created_from || !this.queryParams.created_to) {
				return null;
			}

			return {
				start: new Date(this.queryParams.created_from),
				end: new Date(this.queryParams.created_to),
			};
		},
	},
	async created() {
		this.isShowFilter = this.hasFilter;
		await this.getCustomerList(this.queryParams);
		await this.getCustomerGroupList();
	},
	methods: {
		...mapActions({
			getCustomerList: 'customers/getCustomerList',
			getCustomerGroupList: 'customerGroups/getCustomerGroupList',
			showToast: 'toast/showToast',
		}),
		toggleFilter() {
			this.isShowFilter = !this.isShowFilter;
		},
		updateUrlParams() {
			const query = cleanObject(cloneDeep(this.queryParams));
			this.$router.push({ query }).catch(() => {});
		},
		handleSearch() {
			this.queryParams.page = null;
			this.updateUrlParams();
		},
		handlePageChange(page) {
			this.queryParams.page = page;
			this.updateUrlParams();
		},
		handleResetFilter() {
			this.queryParams = {
				q: null,
				customer_status: null,
				customer_group_id: null,
				created_from: null,
				created_to: null,
				page: null,
				r: null,
			};

			this.updateUrlParams();
		},
		exportCustomer() {
			this.$refs['modal-export-customer'].open();
		},
		async handleExportCustomer() {
			this.isExporting = true;
			try {
				await postCustomerExport({
					...this.queryParams,
					report_key: 'customer_management',
				});
				this.showToast({
					header: this.$t('global.successMessageTitle'),
					content: `Your request has been completed.`,
					type: 'success',
				});
			} catch (e) {
				this.showToast({
					header: this.$t('global.errorMessageExport'),
					content: this.$t('global.errorMessage'),
					type: 'danger',
				});
			} finally {
				this.isExporting = false;
				this.$refs['modal-export-customer'].close();
			}
		},
		handleFilterDateChange(event) {
			const { start, end } = event ? convertDateRangeToUTC(event) : {};

			this.queryParams.page = null;
			this.queryParams.created_from = start;
			this.queryParams.created_to = end;
			this.updateUrlParams();
		},
	},
};
</script>
